import { russianDefaultPaymentCards } from "app/constants/CurrencyConstants";

export const reducer: typeof window.kinosite.reducer  = {
	cinema: {
		address: 'пр. Космонавтов, 19а/28ж',
		place: null,
		phoneNumbers: ['8 (903) 407-28-00', '8 (863) 311-20-09'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/kinopolisubiley',
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: null,
			googleplay: null,
		},
		payment: {
			active: true,
			card: russianDefaultPaymentCards,
		},
		maps: 'https://yandex.ru/maps/-/CBuGjLTzLB',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
	},
	feedback: true,
};
